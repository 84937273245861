import './../node_modules/core-js/index' // <- at the top of your entry point

// The Vue build version to load with the `import` command
// (runtime-only or standalone) has been set in webpack.base.conf with an alias.
import Vue from 'vue'
import App from './App'
import VueRouter from 'vue-router'
import Vuent from 'vuent'
import i18n from './i18n'

import router from './router'
import mixins from './mixins'

import './assets/scss/legau-style.scss'

import axios from 'axios'
import { datadogRum } from '@datadog/browser-rum'

Vue.use(VueRouter)
Vue.use(Vuent)
Vue.mixin(mixins)
axios.defaults.headers.post['Content-Type'] = 'application/json; charset=utf-8'
axios.defaults.withCredentials = true

Vue.prototype.$http = axios.create({
  baseURL: process.env.VUE_APP_API_BASE
})

const Office = window.Office

new Vue({ // eslint-disable-line
  el: '#app',
  router,
  components: { App },
  template: '<App/>',
  i18n,
  data () {
    return {
      user: null,
      pingInterval: null,
      csfrRetries: 0,
      error: false
    }
  },
  created () {
    Office.onReady()

    if (process.env.NODE_ENV === 'production') {
      datadogRum.init({
        applicationId: '986a0379-d041-4cd7-86cb-6cc343336edb',
        clientToken: 'pub8c932fceee372969cde42b9580606ce5',
        site: 'datadoghq.eu',
        service: 'frontend',
        env: process.env.VUE_APP_LEGAU_ENV,
        // Specify a version number to identify the deployed version of your application in Datadog
        version: 'legacy-23.10',
        sessionSampleRate: 100,
        sessionReplaySampleRate: 20,
        trackUserInteractions: true,
        trackResources: true,
        trackLongTasks: true,
        defaultPrivacyLevel: 'allow'
      })
    }
  },
  methods: {
    setupUser (user) {
      this.$root.user = user
      dataLayer.push({ userId: user.id })
      if (!window.location.href.includes('$isDialog')) {
        this.pingInterval = setInterval(function () {
          this.pingServer()
        }.bind(this), 30000)
      }
    },
    pingServer () {
      this.csfrRetries = 0
      this.$http.post('/ping', {
        page: this.$route.name,
        interval: 30
      }).catch(error => {
        // session expired
        if (error.response && error.response.status === 401) {
          this.logout(true)
        }

        // csrf token expired
        if (error.response && error.response.status === 419) {
          this.getCsrfCookie()
        }
      })
    },
    getCsrfCookie () {
      if (this.csfrRetries > 5) {
        this.error = 'CSRF mismatch'
        return
      }
      this.csfrRetries++
      return this.$http.get('/csrf-cookie')
    },
    logout (kickout) {
      clearInterval(this.pingInterval)
      this.pingInterval = null
      let route = '/login'
      if (kickout) {
        route += '?kickout=1&returnUrl=' + encodeURIComponent(this.$route.path) +
          '&email=' + encodeURIComponent(this.$root.user.email)
      } else {
        this.$http.post('/auth/logout')
      }

      this.$root.user = null
      this.$router.push(route)
    }
  }
})
