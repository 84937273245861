<template>
  <div class="main-menu">
    <div
      class="arrow previous"
      :class="{ 'visible': leftArrowVisible }"
      @click.stop.prevent="scrollLeft"
    >
      <a href="#"><i class="ms-Icon ms-Icon--ChevronLeftSmall" /></a>
    </div>
    <ul id="mainMenu">
      <router-link
        :to="{name: 'contracts'}"
        tag="li"
      >
        <a href="#">{{ $t('optionSelection.contracts') }}</a>
      </router-link>
      <router-link
        v-if="$root.user.features.has_knowledge"
        :to="{name: 'knowledge-search'}"
        tag="li"
      >
        <a href="#">{{ $t('optionSelection.knowledgeSearch') }}</a>
      </router-link>
      <router-link
        :to="{name: 'jurisprudence'}"
        tag="li"
      >
        <a href="#">{{ $t('optionSelection.jurisprudence') }}</a>
      </router-link>
      <router-link
        :to="{name: 'doctrine'}"
        tag="li"
      >
        <a href="#">{{ $t('optionSelection.doctrine') }}</a>
      </router-link>
    </ul>
    <div
      class="arrow next"
      :class="{ 'visible': rightArrowVisible }"
      @click.stop.prevent="scrollRight"
    >
      <a href="#"><i class="ms-Icon ms-Icon--ChevronRightSmall" /></a>
    </div>
  </div>
</template>

<script>
export default {
  name: 'MainMenu',
  data () {
    return {
      leftArrowVisible: false,
      rightArrowVisible: false,
      element: null,
      scrollableWidth: null
    }
  },
  mounted () {
    this.element = document.getElementById('mainMenu')
    // ensures arrows are setup properly
    this.calcOverflow()
    window.addEventListener('resize', () => {
      this.calcOverflow()
    })
  },
  methods: {
    calcOverflow () {
      this.scrollableWidth = this.element.scrollWidth - this.element.clientWidth
      this.scroll(0)
    },
    scrollLeft () {
      return this.scroll(-30)
    },
    scrollRight () {
      return this.scroll(30)
    },
    scroll (to) {
      if (this.scrollableWidth <= 0) {
        // no overflow
        this.leftArrowVisible = this.rightArrowVisible = false
      }

      const oldScrollPosition = this.element.scrollLeft
      this.element.scrollLeft = Math.min(oldScrollPosition + to, this.scrollableWidth)
      this.leftArrowVisible = (this.element.scrollLeft > 0)
      this.rightArrowVisible = (this.element.scrollLeft < this.scrollableWidth)
    }
  }
}
</script>

<style lang="scss" scoped>
@import '../assets/scss/abstracts';
div.main-menu {
  position: relative;
  overflow: hidden;
  margin-right: 10px; // a word feature is on the way

  > * {
    margin-top: 10px;
  }

  div.arrow {
    top: 0;
    padding: 10px 0;
    height: 100%;
    width: 20px;
    position: absolute;
    display: none;
    background: $blue-dark;

    &.visible {
      display: block;
    }

    &.previous {
      left: 0;
    }

    &.next {
      right: 0;
      padding-right: 5px;
    }
  }

  ul {
    display: flex;
    padding-left: 0;
    margin-top: 11px;
    margin-bottom: 0;
    width: 100%;
    overflow-x: hidden;

    li {
      padding: 10px 10px;
      list-style: none;

      a {
        color: #fff;
        font-size: 14px;
        font-weight: bold;

        &:hover {
          text-decoration: underline;
        }
      }

      &.router-link-active {
        background-color: white;
        a {
          color: $black;

          &:hover {
            text-decoration: none;
          }
        }
      }
    }
  }

}
</style>
