import Router from 'vue-router'

const Login = () => import('./views/Login.vue')
const DocumentListing = () => import('./views/documents/DocumentListing.vue')
const DocumentTemplate = () => import('./views/documents/DocumentTemplate.vue')
const KnowledgeSearch = () => import('./views/knowledge/KnowledgeSearch.vue')
const DocumentAssistant = () => import('./views/documents/DocumentAssistant.vue')
const Jurisprudence = () => import('@/views/court_decisions/Jurisprudence.vue')
const Index = () => import('@/views/Index.vue')
const JudgementDetail = () => import('@/views/court_decisions/JudgementDetail.vue')
const ArticleSearch = () => import('@/views/articles/Doctrine.vue')
const ArticleDetail = () => import('@/views/articles/ArticleDetail.vue')
const ThirdPartyContracts = () => import('@/views/external_documents/ThirdPartyContracts.vue')
const ThirdPartyContractDetail = () => import('@/views/external_documents/ThirdPartyContractDetail.vue')

const router = new Router({
  routes: [
    { path: '*', redirect: '/' },
    {
      path: '/login',
      name: 'login',
      component: Login,
      meta: { title: 'welcome.title' }
    },
    {
      path: '/',
      name: 'index',
      component: Index,
      meta: { title: 'welcome.title' }
    },
    {
      path: '/contracts',
      name: 'contracts',
      component: DocumentListing,
      meta: { title: 'contractListing.title' }
    },
    {
      path: '/3rd-party-contracts',
      name: '3rd-party-contracts',
      component: ThirdPartyContracts,
      meta: { title: 'thirdPartyContracts.title' }
    },
    {
      path: '/3rd-party-contracts/:contract_id',
      name: '3rd-party-contract',
      component: ThirdPartyContractDetail,
      meta: { title: '', dialog: true }
    },
    {
      path: '/jurisprudence',
      name: 'jurisprudence',
      component: Jurisprudence,
      meta: { title: 'jurisprudence.title' }
    },
    {
      path: '/jurisprudence/:judgement_id',
      name: 'judgement',
      component: JudgementDetail,
      meta: { title: '', dialog: true }
    },
    {
      path: '/contracts/:document_uuid/assistant',
      name: 'contract-assistant',
      component: DocumentAssistant,
      meta: { title: 'contract.title' }
    },
    {
      path: '/contracts/:document_uuid',
      name: 'contract',
      component: DocumentTemplate,
      meta: { title: 'contract.title' }
    },
    {
      path: '/articles',
      name: 'doctrine',
      component: ArticleSearch,
      meta: { title: 'doctrine.title' }
    },
    {
      path: '/articles/:article_id',
      name: 'doctrine-view',
      component: ArticleDetail,
      meta: { title: '', dialog: true }
    },
    {
      path: '/knowledge-search',
      name: 'knowledge-search',
      component: KnowledgeSearch,
      meta: { title: '' }
    }
  ]
})

export default router
