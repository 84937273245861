import { enGB as enDateFormat } from 'date-fns/locale'

export default {
  date: enDateFormat,
  layout: {
    compatibilityNotice: 'Está utilizando uma versão desatualizada do Office. O suporte do Legaü para esta versão é limitado. Recomendamos que atualize o Office para aproveitar ao máximo os recursos do Legaü.',
    fail: 'Something went wrong. Please restart Legaü and/or contact support@legau.pt.',
    hello: 'Hello',
    seeMore: 'see more',
    recordCount: 'results',
    noResults: 'No results',
    searchLabel: 'Search here',
    searchPrompt: 'Please enter a search keyword.',
    selectTooltip: 'Add to selection',
    selectionCta: 'To quote content, select it from the left-hand side.',
    searchButton: 'Search within the document',
    selectionComplete: 'Selection complete',
    removeSelection: 'Remove',
    expand: 'Expand',
    collapse: 'Collapse'
  },
  favorites: {
    heading: 'Favoritos',
    information: 'Aqui serão listados os seus favoritos. Estes podem ser criados na pesquisa de jurisprudencia e doutrina através do ícone {icon}.',
    dialog: {
      headerCreate: 'Adicionar favorito',
      headerEdit: 'Editar favorito'
    },
    add: 'Adicionar',
    addIcon: 'Adicionar aos favoritos',
    removeIcon: 'Remover dos favoritos',
    folderDialog: {
      headerCreate: 'Criar pasta',
      headerEdit: 'Editar pasta',
      nameField: 'Nome',
      parentFolderField: 'Pasta',
      create: 'Criar',
      submit: 'Submeter'
    },
    deleteFolderMessage: 'Are you sure you want to delete the folder "{name}"? All favorites within it will be deleted as well.',
    deleteEntryMessage: 'Are you sure you want to delete the favorite "{name}"?'
  },
  welcome: {
    title: 'Welcome to Legaü',
    first: 'Ready to untap the true lawyer in you and leave the dull word behind?',
    second: 'We are on a mission to make you save time, increase revenue and draft legal documents better.',
    support: 'Problems signing in? Reach out!',
    invalid: 'Invalid email/password.',
    cta: 'Sign in',
    restart: 'Restart',
    sessionExpired: 'Your session expired, please login again.',
    accountInactive: 'Your account is not active. Please reach out to your support.',
    invalidRequest: 'Something went wrong with your request. Please re-open the app and try again.'
  },
  contractListing: {
    title: 'Select one contract type',
    thirdPartyContracts: 'Third Party Contracts'
  },
  contract: {
    title: 'Contract',
    epigraph_select: 'Select a topic:',
    enter_keyword: 'insert your keyword',
    assistant_title: 'Contract Assistant {contract}',
    public_contracts: 'Contracts Legaü',
    organization_contracts: 'Contracts from your organization',
    templateCta: 'Please enter a search keyword or select a clause heading.',
    loadTemplate: 'Load your template',
    selectTemplate: 'Select a template',
    selectTemplateError: 'Please select a template',
    topics: 'Clauses'
  },
  thirdPartyContracts: {
    title: 'Third Party Contracts'
  },
  jurisprudence: {
    title: 'Court Decisions',
    searchLabel: 'Search here',
    searchPrompt: 'Please enter a search keyword.',
    courtSearch: 'Filter by Courts',
    filterbydate: 'Filter by Date',
    dateFilter: {
      lastYear: 'last year',
      lastMonth: 'last month',
      last3Months: 'last 3 months',
      last6Months: 'last 6 months',
      date: 'Date',
      dateFrom: 'From',
      dateTo: 'To'
    }
  },
  judgementDetail: {
    title: 'Court ruling from {court} - {date} - Process no. {processNumber}',
    categories: {
      summary: 'Summary',
      courtReport: 'Court Report',
      argumentationParties: 'Arguments of the Parties',
      argumentationCourt: 'Arguments of the Court',
      decision: 'Decision',
      facts: 'Facts',
      fullText: 'Full Text of Court Decision'
    },
    pageTitle: 'Judgement detail {processNumber}'
  },
  doctrine: {
    title: 'Legal Papers',
    searchPrompt: 'Please enter a search keyword.',
    searchLabel: 'Search here',
    disclaimer: 'Conteúdo externo alojado em:',
    filters: {
      author: {
        title: 'Author',
        placeholder: 'Search for author'
      },
      year: {
        title: 'Year'
      },
      magazine: {
        title: 'Publication'
      },
      articleType: {
        title: 'Type',
        options: {
          article: 'Article',
          magazine: 'Magazine',
          thesis: 'Thesis',
          book: 'Book',
          other: 'Other'
        }
      }
    }
  },
  optionSelection: {
    contracts: 'Contracts',
    jurisprudence: 'Court Decisions',
    doctrine: 'Legal Papers',
    knowledgeSearch: 'Knowledge',
    text: 'Select one of the options below on or the above menu.'
  },
  knowledgeSearch: {
    searchTerm: 'Term to search',
    nextExcerpt: 'Next excerpt',
    previousExcerpt: 'Previous excerpt',
    openDocument: 'Open document',
    filters: {
      searchIn: {
        content: 'Search content',
        metadata: 'Search metadata'
      },
      author: {
        title: 'Author',
        searchTip: 'Search for full name or initials.'
      },
      topic: {
        title: 'Matter',
        searchTip: 'Search a matter.'
      },
      custom: {
        searchTip: 'Search a {field}.'
      },
      date: {
        title: 'Date',
        between: 'between',
        and: 'and',
        searchCta: 'Search'
      }
    }
  }
}
