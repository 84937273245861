import { pt as dateFormat } from 'date-fns/locale'

export default {
  date: dateFormat,
  layout: {
    compatibilityNotice: 'Está utilizando uma versão desatualizada do Office. O suporte do Legaü para esta versão é limitado. Recomendamos que atualize o Office para aproveitar ao máximo os recursos do Legaü.',
    fail: 'Algo correu mal. Por favor renicie o Legaü e/ou contacte support@legau.pt.',
    hello: 'Olá',
    seeMore: 'ver mais',
    recordCount: 'entradas',
    noResults: 'Sem resultados',
    searchLabel: 'Pesquise aqui',
    searchPrompt: 'Por favor insira um termo para pesquisar.',
    selectTooltip: 'Adicionar à seleção',
    selectionCta: 'Para citar conteúdo, selecione o mesmo do lado esquerdo.',
    searchButton: 'Pesquisar no documento',
    selectionComplete: 'Seleção completa',
    removeSelection: 'Remover',
    expand: 'Expandir',
    collapse: 'Estreitar',
    cancel: 'Cancelar',
    close: 'Fechar'
  },
  share: {
    header: 'Partilhar com pessoas ou grupos',
    submit: 'Partilhar',
    whoHasAccess: 'Quem tem acesso',
    messagePlaceholder: 'Adicionar uma mensagem (opcional)',
    sharedWithMe: 'Partilhado comigo',
    shareButton: 'Partilhar',
    shareClipboard: 'Copiar link',
    shareEmail: 'Enviar por email',
    shareEmailSubject: 'Acórdão do {court} - Processo {process_number} de {date}',
    shareEmailBody: 'Olá,%0D{sharer} partilhou consigo o acórdão {court}, {process_number}, {date}.%0D%0DLink para o acórdao: {link}%0D'
  },
  favorites: {
    heading: 'Favoritos',
    information: 'Aqui serão listados os seus favoritos. Estes podem ser criados na pesquisa de jurisprudencia e doutrina através do ícone {icon}.',
    dialog: {
      headerCreate: 'Adicionar favorito',
      headerEdit: 'Editar favorito'
    },
    add: 'Adicionar',
    addIcon: 'Adicionar aos favoritos',
    removeIcon: 'Remover dos favoritos',
    folderDialog: {
      headerCreate: 'Criar pasta',
      headerEdit: 'Editar pasta',
      nameField: 'Nome',
      parentFolderField: 'Pasta',
      create: 'Criar',
      submit: 'Submeter'
    },
    deleteFolderMessage: 'Tem a certeza que deseja apagar a pasta "{name}"? O conteúdo da pasta será apagado também.',
    deleteEntryMessage: 'Tem a certeza que deseja apagar o favorito "{name}"?'
  },
  welcome: {
    title: 'Bem-vindo ao Legaü',
    first: 'Pronto para descobrir o verdadeiro advogado que há em si e deixar o trabalho chato para trás?',
    second: 'Estamos numa missão para faze-lo poupar tempo, aumentar a receita e produzir melhores documentos jurídicos!',
    support: 'Problemas? Contacte-nos!',
    invalid: 'Combinação inválida.',
    cta: 'Entrar',
    restart: 'Reiniciar',
    sessionExpired: 'A sua sessão expirou, por favor efectue login novamente.',
    accountInactive: 'A sua conta não está activa. Por favor entre em contacto connosco.',
    invalidRequest: 'Algo correu mal com o seu pedido. Por favor reinicie a apliação e tente de novo.'
  },
  contractListing: {
    title: 'Selecione uma categoria'
  },
  contract: {
    title: 'Contrato',
    epigraph_select: 'Selecione uma epígrafe',
    enter_keyword: 'Pesquise aqui',
    assistant_title: 'Contract Assistant {contract}',
    public_contracts: 'Documentos Legaü',
    organization_contracts: 'Documentos da minha organização',
    templateCta: 'Por favor insira um termo para pesquisar ou selecione uma epígrafe.',
    loadTemplate: 'Carregar minuta',
    selectTemplate: 'Selecione uma minuta / modelo',
    selectTemplateError: 'Por favor selecione um modelo',
    topics: 'Cláusulas',
    templateLoad: 'Carregar',
    templates: 'Minutas',
    documentsOwner: 'Documentos {owner}',
    clauseBank: 'Ver cláusulas',
    viewContent: 'Ver conteúdo',
    content: 'Conteúdo',
    documents: 'Documentos'
  },
  thirdPartyContracts: {
    title: 'Documentos de Terceiros'
  },
  jurisprudence: {
    title: 'Jurisprudência',
    searchLabel: 'Pesquise aqui',
    searchPrompt: 'Por favor insira um termo para pesquisar.',
    courtSearch: 'Filtre por tribunais',
    actSearch: 'Filtre por tipo de ato',
    corutSearchPlaceholder: 'Seleccionar tribunais',
    filterbydate: 'Filtrar por data',
    privateOnly: 'Apenas conteúdo carregado pela organização',
    decision: 'Acórdão',
    advocateGeneralConclusion: 'Conclusão do Advogado Geral',
    dateFilter: {
      lastYear: 'último ano',
      lastMonth: 'último mes',
      last3Months: 'últimos 3 meses',
      last6Months: 'últimos 6 meses',
      date: 'Data',
      dateFrom: 'Desde',
      dateTo: 'Até'
    },
    orderBy: 'Ordenar por',
    sortOptions: {
      relevance: 'Relevância',
      date: 'Data (mais recente primeiro)'
    },
    iconOpen: 'Abrir acórdão'
  },
  judgementDetail: {
    title: 'Acórdão do {court} - {date} - Processo {processNumber}',
    redactor: 'Relator',
    decision: 'Decisão',
    categories: {
      summary: 'Sumário',
      courtReport: 'Relatório',
      argumentationParties: 'Argumentação das Partes',
      argumentationCourt: 'Argumentação do Tribunal',
      decision: 'Decisão',
      facts: 'fatos',
      fullText: 'Texto integral'
    },
    pageTitle: 'Judgement detail {processNumber}'
  },
  doctrine: {
    title: 'Doutrina',
    searchTerm: 'Termo a pesquisar',
    searchLabel: 'Pesquise aqui',
    fileSelectorLabel: 'Ficheiro',
    disclaimer_external: 'Conteúdo externo alojado em:',
    disclaimer_internal: 'Conteúdo interno da organização',
    icons: {
      openAccess: 'Acesso aberto',
      closedAccess: 'Acesso fechado',
      pdf: 'Abrir ficheiro',
      pdf_preview: 'Pré-visualização do documento',
      webpage: 'Abrir fonte',
      quote: 'Citar no documento'
    },
    filters: {
      author: {
        title: 'Autor',
        placeholder: 'Nome de autor a pesquisar'
      },
      year: {
        title: 'Ano'
      },
      publisher: {
        title: 'Editora'
      },
      magazine: {
        title: 'Publicação'
      },
      articleType: {
        title: 'Tipo',
        options: {
          article: 'Artigo',
          magazine: 'Revista',
          thesis: 'Tese',
          book: 'Livro',
          other: 'Outro'
        }
      }
    }
  },
  optionSelection: {
    contracts: 'Documentos',
    jurisprudence: 'Jurisprudência',
    doctrine: 'Doutrina',
    knowledgeSearch: 'Conhecimento',
    text: 'Pode voltar a este ecrã clicando no símbolo no canto superior esquerdo. As funcionalidades habituais do Legaü estão disponíveis no menu superior.'
  },
  knowledgeSearch: {
    searchTerm: 'Termo a pesquisar',
    nextExcerpt: 'Próximo excerto',
    previousExcerpt: 'Excerto anterior',
    openDocument: 'Abrir documento',
    filters: {
      searchIn: {
        title: 'Pesquisar termo em',
        content: 'Conteúdo do documento',
        metadata: 'Titulo do documento',
        both: 'Ambos'
      },
      docType: {
        title: 'Tipo de documento'
      },
      author: {
        title: 'Autor',
        searchTip: 'Pesquise por nome de autor completo ou iniciais.'
      },
      topic: {
        title: 'Assunto',
        searchTip: 'Pesquise um assunto.'
      },
      custom: {
        searchTip: 'Pesquise um {field}.'
      },
      date: {
        title: 'Data',
        between: 'entre',
        and: 'e',
        searchCta: 'Pesquisar'
      }
    }
  },
  notes: {
    notes: 'Notas',
    noNotes: 'Sem notas',
    insertNewNote: 'Inserir nova nota',
    save: 'Guardar'
  }
}
