<template>
  <div id="app">
    <div
      v-if="$root.error"
      style="display: flex; height: 100%; text-align: center"
    >
      <div style="margin: auto; width: 100%;">
        {{ $t('layout.fail') }}

        <pre>{{ $root.error }}</pre>
      </div>
    </div>
    <div v-else>
      <div
        v-if="displayCompatibilityNotice"
        class="compatibility-notice"
      >
        {{ $t('layout.compatibilityNotice') }}
        <a
          href="#"
          @click.stop.prevent="omitCompatibilityNotice"
        >
          [OK]
        </a>
      </div>
      <div id="content-header">
        <div class="logo-container">
          <router-link :to="{name: 'index'}">
            <img
              src="./assets/img/icon-32.png"
              width="25"
              height="25"
              alt="legau"
            >
          </router-link>
        </div>
        <h1
          v-if="!isLoggedIn"
          class="ms-font-xl"
        >
          {{ headingText }}
        </h1>
        <MainMenu v-if="isLoggedIn" />
      </div>
      <div
        id="content-main"
        :class="{ 'no-footer': !isLoggedIn }"
      >
        <router-view v-if="loaded" />
        <Loader v-else />
      </div>
      <footer
        v-if="isLoggedIn"
        class="ms-font-m user-data"
      >
        <i
          class="ms-Icon ms-Icon--Contact"
          aria-hidden="true"
        />
        <span style="margin: 0 5px">{{ $root.user.first_name }} {{ $root.user.last_name }}</span>
        <vnt-button
          class="button-sm"
          :click="() => $root.logout(false)"
        >
          Logout
        </vnt-button>
      </footer>
    </div>
  </div>
</template>

<script>
import Loader from './components/Loader'
import MainMenu from './components/MainMenu'

export default {
  name: 'App',
  components: { MainMenu, Loader },
  data () {
    return {
      displayCompatibilityNotice: true,
      loaded: false,
      headingText: null
    }
  },
  computed: {
    isLoggedIn () {
      return this.$root.user && !this.$route.meta.dialog
    }
  },
  created () {
    if (window.sessionStorage.getItem('dismissedCompatibilityNotice')) {
      this.displayCompatibilityNotice = false
    }

    this.$root.getCsrfCookie().then(response => {
      this.$http.get('/auth/status').then((response) => {
        if (response.data === '') {
          this.$router.replace('/login')
        } else {
          this.$root.setupUser(response.data)
        }
      }).catch(() => {
        if (this.$route.name !== 'login') {
          this.$router.replace('/login')
        }
      }).finally(() => {
        this.loaded = true
      })
    })

    this.$on('headingText', (text) => { this.headingText = text })
    this.$router.afterEach((to, from) => {
      this.headingText = this.$t(to.meta.title)
    })

    this.$on('pageTitle', (text) => {
      document.title = text
      window.dataLayer.push({ event: 'custom_page_view' })
    })
  },
  methods: {
    omitCompatibilityNotice () {
      window.sessionStorage.setItem('dismissedCompatibilityNotice', '1')
      this.displayCompatibilityNotice = false
    }
  }
}
</script>

<style lang="scss">
@import './assets/scss/abstracts';
div#app {
  height: 100%;
}

.compatibility-notice {
  background: $orange;
  padding: 2px;
  font-size: small;
}

#content-header {
  background: $blue-dark;
  display: flex;
  color: $white;
  width: 100%;
  height: 50px;

  .logo-container {
    margin: 15px 10px 0;
    align-items: center;
  }

  h1 {
    margin: 10px;
  }

  h1.back {
    margin-right: 10px;
    cursor: pointer;

    &.no-route {
      visibility: hidden;
    }
  }

}

footer.user-data {
  background: $neutralSecondary;
  position: absolute;
  bottom: 0;
  left: 0;
  height: 40px;
  padding-top: 5px;
  padding-right: 10px;
  color: $white;
  width: 100%;
  text-align: right;

  button {
    margin-left: 5px;
  }
}

#content-main {
    background: #fff;
    display: flex;
    // max-width: 1440px;
    overflow: auto;
    max-height: calc(100vh - 120px);
    height: 100%;
    // padding: 0 10px; removed to fix the JudgmentDetail layout
    // padding should be applied on a element-need basis

    &.no-footer {
      max-height: calc(100vh - 50px);
    }
}

#app > div {
  display: flex;
  flex-direction: column;
  height: 100%;
}
</style>
