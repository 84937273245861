<template>
  <div class="preloader">
    <div class="ms-Spinner" />
  </div>
</template>

<script>
export default {
  name: 'LegauLoader',
  mounted () {
    const SpinnerElements = document.querySelectorAll('.ms-Spinner')
    for (let i = 0; i < SpinnerElements.length; i++) {
      // eslint-disable-next-line no-new
      new fabric.Spinner(SpinnerElements[i])
    }
  }
}
</script>

<style>
    .preloader {
      padding: 20px;
      display: block;
      margin: auto;
      width: 50px;
    }
</style>
});
