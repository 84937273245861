const mixins = {
  methods: {
    handleOfficeJsError (error) {
      console.log('Error: ' + error)
      if (error instanceof OfficeExtension.Error) {
        console.log('Debug info: ' + JSON.stringify(error.debugInfo))
      }
    }
  }
}

export default mixins
