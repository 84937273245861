import Vue from 'vue'
import VueI18n from 'vue-i18n'

// messages
import enTranslations from './messages/en.js'
import ptTranslations from './messages/pt.js'

Vue.use(VueI18n)

const messages = {
  en: enTranslations,
  pt: ptTranslations
}

const i18n = new VueI18n({
  locale: 'pt', // set locale
  fallbackLocale: 'pt', // set fallback locale
  messages // set locale messages
})

export default i18n
